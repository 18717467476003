import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  InputLabel,
} from '@material-ui/core';

import { createProject, updateProject } from '../../../requests/api/project';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import useStyles from '../styles';

const ProjectForm = ({
  reload,
  currentSelection,
  setCurrentOpen,
  options,
  setError,
  setSucc,
  update,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState({
    id: currentSelection.id,
    name: currentSelection.name,
    companyId: currentSelection.companyId,
    tokens: currentSelection.tokens || 0,
  });

  const handleClose = () => {
    setCurrentOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputValues({
      ...inputValues,
      [id]: value,
    });
  };

  const selectCompany = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      companyId: value,
    });
  };

  const handleSend = async () => {
    let response;
    if ((Object.values(inputValues).some((value) => value === ''))) {
      setError(t('administration.validation'));
      return;
    }

    if (inputValues.name.length < 3) {
      setError(t('administration.nameLength'));
      return;
    }
    if (update) {
      response = await updateProject(inputValues);
    } else {
      response = await createProject(inputValues);
    }
    if (response.success) {
      setSucc(t('administration.success'));
      handleClose();
      reload();
    } else {
      setError(t('administration.error'));
      handleClose();
      reload();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };
  return (
        <div>
            <div className={styles.formWrapper}>
              <Grid container spacing={2} alignItems='flex-end'>
                  <Grid item xs={5}>
                  <InputLabel>{t('administration.project.nameForm')}</InputLabel>
                  <TextField
                    id="name"
                    type="text"
                    variant="outlined"
                    value={inputValues.name}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel>{t('administration.project.company')}</InputLabel>
                  <TextField
                    id="companyId"
                    type="text"
                    variant="outlined"
                    value={inputValues.companyId}
                    options= {options}
                    onChange={selectCompany}
                    select={true}
                    fullWidth
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>{'Tokens'}</InputLabel>
                  <TextField
                    id="tokens"
                    type="number"
                    variant="outlined"
                    value={inputValues.tokens}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      onKeyDown: handleKeyDown,
                    }}
                  />
                </Grid>
                <Grid item xs= {8}/>
                <Grid item xs={4}>
                <Button
                  id="submit-ticket-btn"
                  onClick={handleSend}
                  text={update ? t('administration.buttons.update') : t('administration.buttons.create')}
                  height="35px"
                  width="100%"
                  backgroundColor="#2C9DC9"
                  color="#FFFFFF"
                  borderRadius="10px"
                />
                </Grid>
                </Grid>
              </div>
        </div>
  );
};

export default ProjectForm;
