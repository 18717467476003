import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  FormControl, InputLabel, TextField, Button,
} from '@material-ui/core';

import useStyles from './styles';

import OutlinedSelect from '../../components/OutlinedSelect';
import Snackbar from '../../components/Snackbar';

import { putEditProduct, postNewProduct } from '../../requests/api/product';
import { getCompanies } from '../../requests/api/company';
import { getCategoriesPerPage } from '../../requests/api/category';

function ProductForm({
  productInfo, setProductInfo, readOnly, setOpenCreate, setUpdate,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const currentCompany = useSelector((state) => state.company.companyId);
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);

  const [inputValues, setInputValues] = useState({
    name: productInfo ? productInfo.name : '',
    companyId: productInfo ? productInfo.Company.id : currentCompany,
    internalCode: productInfo ? productInfo.internalCode : '',
    ean: productInfo ? productInfo.ean : '',
    distributor: productInfo ? productInfo.distributor : '',
    description: productInfo ? productInfo.description : '',
    size: productInfo ? productInfo.size : '',
    categoryCode: productInfo && productInfo.Category ? productInfo.Category.code : '',
    imageUrl: productInfo ? productInfo.imageUrl : '',
    subCategory: productInfo ? productInfo.subCategory : '',
    deleted: productInfo ? productInfo.deleted : false,
  });

  // Edit product info
  const handleChangeProductInfo = async (event) => {
    const { id, name, value } = event.target;

    if (!id) {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [id]: value,
      });
    }
  };

  // Saves the new data
  const handleSave = async () => {
    try {
      let response = null;
      if (productInfo) {
        response = await putEditProduct(productInfo.id, inputValues);
      } else {
        if (inputValues.name === '') {
          setSeverity('error');
          setMessage(t('products.productForm.nameError'));
          return null;
        }
        if (inputValues.internalCode === '') {
          setSeverity('error');
          setMessage(t('products.productForm.internalCodeError'));
          return null;
        }
        // A product that is being created should not be eliminated.
        // This is why the deleted property is removed.
        delete inputValues.deleted;
        // If any of the inputs is not filled, it is set
        // to null so that it is correctly stored in the db.
        let cleanValues = {};
        Object.entries(inputValues).forEach(([key, inputValue]) => {
          let newValue;
          if (inputValue === '') {
            newValue = null;
          } else {
            newValue = inputValue;
          }
          cleanValues = { ...cleanValues, [key]: newValue };
        });
        response = await postNewProduct(cleanValues);
      }
      if (response.status === 200) {
        setSeverity('success');
        setMessage(t('products.productForm.successMessage'));

        setUpdate((prevState) => !prevState);
        if (productInfo) {
          setTimeout(() => {
            setProductInfo(null);
          }, 500);
        } else {
          setOpenCreate(false);
        }
      } else {
        setSeverity('error');
        setMessage(response.data.errorMessage);
      }
    } catch (err) {
      setSeverity('error');
      setMessage('Error');
      return (err);
    }
    return (false);
  };

  useEffect(() => {
    const getCategoryOptions = async () => {
      const response = await getCategoriesPerPage(
        0, 1000, '', null,
      );

      const sortedCategories = response.data.data.categories.sort(
        (a, b) => a.name.localeCompare(b.name),
      );

      if (response.status === 200) {
        const categoryOptions = sortedCategories.map((category) => (
          { name: category.name, value: category.code }));

        categoryOptions.unshift({ name: 'Seleccione categoría', value: '' });
        setCategories(categoryOptions);
      }
    };

    getCategoryOptions();
  }, []);

  useEffect(() => {
    const getCompaniesOptions = async () => {
      const response = await getCompanies();
      if (response.status === 200) {
        const companiesOptions = response.data.data.companies.map((company) => (
          { name: company.name, value: company.id })).filter(
          (c) => (c.value === currentCompany),
        );
        setCompanies(companiesOptions);
      }
    };

    getCompaniesOptions();
  }, [currentCompany]);

  return (
    <div className={styles.productInfo}>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.name')}</InputLabel>
        <TextField
          id="name"
          value={inputValues.name}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.company')}</InputLabel>
        <OutlinedSelect
          name="companyId"
          items={companies}
          value={inputValues.companyId}
          onChange={handleChangeProductInfo}
          fullWidth
          disabled
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.internalCode')}</InputLabel>
        <TextField
          id="internalCode"
          value={inputValues.internalCode}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.ean')}</InputLabel>
        <TextField
          id="ean"
          value={inputValues.ean}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.description')}</InputLabel>
        <TextField
          id="description"
          value={inputValues.description}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.size')}</InputLabel>
        <TextField
          id="size"
          value={inputValues.size}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.category')}</InputLabel>
        <OutlinedSelect
          name="categoryCode"
          items={categories}
          value={inputValues.categoryCode || ''}
          onChange={handleChangeProductInfo}
          fullWidth
          disabled={readOnly}
        />
      </FormControl>
      {/* <FormControl fullWidth>
        <InputLabel>{t('products.productForm.subCategory')}</InputLabel>
        <OutlinedSelect
          name="subCategory"
          items={[
            { name: 'Seleccione subcategoría', value: '' },
            { name: 'Subcategoría 1', value: 'Subcategoría 1' },
            { name: 'Subcategoría 2', value: 'Subcategoría 2' },
            { name: 'Subcategoría 3', value: 'Subcategoría 3' },
          ]}
          value={inputValues.subCategory || ''}
          onChange={handleChangeProductInfo}
          fullWidth
          disabled={readOnly}
        />
      </FormControl> */}
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.imageUrl')}</InputLabel>
        <TextField
          id="imageUrl"
          value={inputValues.imageUrl}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('products.productForm.distributor')}</InputLabel>
        <TextField
          id="distributor"
          value={inputValues.distributor}
          variant="outlined"
          fullWidth
          onChange={handleChangeProductInfo}
          disabled={readOnly}
        />
      </FormControl>
      {productInfo && (
        <FormControl fullWidth>
          <InputLabel>{t('products.productForm.deleted')}</InputLabel>
          <OutlinedSelect
            name="deleted"
            items={[
              { name: 'Eliminado', value: true },
              { name: 'No eliminado', value: false },
            ]}
            value={inputValues.deleted}
            onChange={handleChangeProductInfo}
            fullWidth
            disabled
          />
        </FormControl>
      )}
      <div className={`${styles.buttonsContainer} ${productInfo ? styles.buttonColumn : ''}`}>
        <Button onClick={handleSave}>
          {productInfo
            ? t('products.productForm.editButton')
            : t('products.productForm.createButton')}
        </Button>
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default ProductForm;
