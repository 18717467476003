import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

// MUI components
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

// MUI Icons
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

// components
import useStyles from './styles';
import { UNAUTHORIZED } from '../../utils/const/http';
import { parseDate } from '../../utils/date';

import Snackbar from '../../components/Snackbar';
import Pagination from '../../components/Pagination';

// API calls
import { getAllTaskResponsesPerPage } from '../../requests/api/task-response';

const Responses = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsToShow, setRowsToShow] = useState(null);
  const rowsPerPage = 10;
  const [taskResponsesQuantity, setTaskResponsesQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  const tableHeaders = [
    {
      id: 'locationName',
      label: <b>{t('qa.tableHeaders.locationName')}</b>,
    },
    {
      id: 'taskName',
      label: <b>{t('qa.tableHeaders.taskName')}</b>,
    },
    {
      id: 'category',
      label: <b>{t('qa.tableHeaders.category')}</b>,
    },
    {
      id: 'userName',
      label: <b>{t('qa.tableHeaders.userName')}</b>,
    },
    {
      id: 'startedAt',
      label: <b>{t('qa.tableHeaders.startedAt')}</b>,
    },
    {
      id: 'finishedAt',
      label: <b>{t('qa.tableHeaders.finishedAt')}</b>,
    },
    {
      id: 'actions',
      label: <b>{t('qa.tableHeaders.actions')}</b>,
    },
  ];

  const requestSearch = async (pageOfTable, limit) => {
    const response = await getAllTaskResponsesPerPage(pageOfTable, limit);
    if (response.success) {
      setRowsToShow(response.data.data.taskResponses);
      setTaskResponsesQuantity(response.data.data.totalTaskResponses);
    }

    if (response.status === UNAUTHORIZED.status) {
      window.location.href = '/home';
    }
  };

  const handleChangePage = (newPage) => {
    const numberNewPage = Number.parseInt(newPage, 10);
    setPage(numberNewPage);
  };

  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    load();
  }, [rowsToShow]);

  useEffect(() => {
    requestSearch(page, rowsPerPage);
  }, [page, rowsPerPage, taskResponsesQuantity]);

  const content = () => {
    if (!taskResponsesQuantity) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={styles.users}>
            <h4>{t('qa.noTaskResponses')}</h4>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {rowsToShow
          .map((row) => (
            <TableRow
              hover
              key={row.id}
            >{row.TaskInstance.Survey
              && <>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className={styles.locationName}
                  width="20%"
                >{row.TaskInstance.Location ? row.TaskInstance.Location.name : t('qa.noLocationName')}</TableCell>
                <TableCell align="left">{row.TaskInstance.Survey ? row.TaskInstance.Survey.title : t('qa.noTaskName')}</TableCell>
                <TableCell align="left">{row.TaskInstance.Category ? `${row.TaskInstance.Category.name} (${row.TaskInstance.Category.code})` : 'General'}</TableCell>
                <TableCell align="left">{row.TaskInstance.User ? row.TaskInstance.User.fullName : t('qa.noUserName')}</TableCell>
                <TableCell align="center">{parseDate(row.startedAt)}</TableCell>
                <TableCell align="center">{parseDate(row.finishedAt)}</TableCell>
                <TableCell align="center">
                  <IconButton component={Link} to={`responses/${row.id}`}>
                    <RemoveRedEyeIcon title={t('qa.review')} />
                  </IconButton>
                </TableCell>
              </>
              }
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h1' className={styles.title} gutterBottom>
            {t('qa.title')}
          </Typography>
          <Typography variant='h4' gutterBottom display="inline">
            {t('qa.subtitle')}
          </Typography>
        </Box>
      </div>
      <Paper className={styles.taskResponseTable}>
        <>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((tableHeader) => (
                    <TableCell
                      key={tableHeader.id}
                      className={clsx({
                        [styles.tableHeader]: true,
                        [styles.taskNameHeader]: ['locationName', 'taskName', 'userName', 'category'].includes(tableHeader.id),
                      })}
                      padding="normal"
                      align="left"
                    >
                      {tableHeader.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} className={styles.progress}>
                      <CircularProgress />
                      <h4>{t('qa.loading')}</h4>
                    </TableCell>
                  </TableRow>
                ) : content()}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            handleChangePage={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            quantity={taskResponsesQuantity}
          />
        </>
      </Paper>
      <div>
        <Snackbar
          open={err !== ''}
          message={err}
          severity="error"
          onClose={() => setErr('')}
        />
        <Snackbar
          open={succ !== ''}
          message={succ}
          severity="success"
          onClose={() => setSucc('')}
        />
      </div>
    </div>
  );
};

export default Responses;
