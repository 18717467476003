import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';

import useStyles from './styles';

import {
  SUPER_ADMIN, CLIENT, OWNER, SCOUT,
  CLIENT_EMPLOYEE,
} from '../../utils/const/user';
import { editUser } from '../../requests/api/user';
import OutlinedSelect from '../../components/OutlinedSelect';
import Snackbar from '../../components/Snackbar';

function UserInformation({
  userInfo, setUserInfo, userEdited, setUserEdited,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  // Edit user info
  // Add errors state near other state declarations
  const [errors, setErrors] = useState({});

  // Modify handleChangeUserInfo to validate password
  const handleChangeUserInfo = async (event) => {
    const { name, value } = event.target;

    // Clear previous error
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));

    // Validate password length if it's not empty
    if (name === 'password' && value && value.length < 8) {
      setErrors((prev) => ({
        ...prev,
        password: t('users.validation.passwordMin'),
      }));
    }

    if (name === 'active') {
      setUserInfo({
        ...userInfo,
        [name]: value === 'active',
      });
    } else {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
  };
  // Back to the user table
  const handleBack = () => {
    setUserInfo(null);
  };

  // Saves the new active status and/or role
  const handleSave = async () => {
    const response = await editUser(
      userInfo.id,
      userInfo.active,
      userInfo.role,
      userInfo.dni,
      userInfo.fullName,
      userInfo.password,
      userInfo.fileUrl,
      userInfo.phoneNumber,
    );
    if (response.success) {
      setSeverity('success');
      setMessage(t('users.userInfo.updateSuccess'));
      setUserEdited(!userEdited);
      setUserInfo(null);
    } else {
      setSeverity('error');
      setMessage(response.error ? response.error.errorMessage : t('users.userInfo.updateError'));
    }
  };

  return (
    <>
      <div className={styles.userInfo}>
        <div>
          <InputLabel>{t('users.userInfo.name')}</InputLabel>
          <TextField
            name = "fullName"
            value={userInfo.fullName}
            variant="outlined"
            fullWidth
            onChange={handleChangeUserInfo}
          />
        </div>
        <div>
          <InputLabel>DNI</InputLabel>
          <TextField
            name = 'dni'
            value={userInfo.dni}
            variant="outlined"
            onChange={handleChangeUserInfo}
            fullWidth />
        </div>
        <div>
          <InputLabel>{t('users.userInfo.email')}</InputLabel>
          <TextField
            value={userInfo.email}
            variant="outlined"
            disabled
            fullWidth
          />
        </div>
        <div>
          <InputLabel>{t('users.userInfo.password')}</InputLabel>
          <TextField
            name='password'
            variant="outlined"
            fullWidth
            onChange={handleChangeUserInfo}
            error={!!errors.password}
            helperText={errors.password || t('users.validation.passwordHelper')}
            type="password"
          />;
        </div>
        <div>
          <InputLabel>{t('users.userInfo.phone')}</InputLabel>
          <TextField
            name = 'phoneNumber'
            placeholder="Ejemplo: 56912345678"
            value={userInfo.phoneNumber}
            variant="outlined"
            type="number"
            onChange={handleChangeUserInfo}
            helperText="Ingrese un número de teléfono en formato internacional, omite el +, paréntesis y guiones."
            fullWidth />
        </div>
        <FormControl fullWidth className={`${styles.selectOulined} ${styles.role}`}>
          <InputLabel>{t('users.userInfo.role')}</InputLabel>
          <OutlinedSelect
            name="role"
            items={[
              { name: OWNER, value: OWNER },
              { name: CLIENT, value: CLIENT },
              { name: CLIENT_EMPLOYEE, value: CLIENT_EMPLOYEE },
              { name: SUPER_ADMIN, value: SUPER_ADMIN },
              { name: SCOUT, value: SCOUT },
            ]}
            value={userInfo.role}
            onChange={handleChangeUserInfo}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth className={styles.selectOulined}>
          <InputLabel>{t('users.userInfo.status')}</InputLabel>
          <OutlinedSelect
            name="active"
            items={[
              { name: t('users.userInfo.active'), value: 'active' },
              { name: t('users.userInfo.inactive'), value: 'inactive' },
            ]}
            value={userInfo.active ? 'active' : 'inactive'}
            onChange={handleChangeUserInfo}
            fullWidth
          />
        </FormControl>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleBack}>{t('users.userInfo.buttons.back')}</Button>
          <Button onClick={handleSave}>{t('users.userInfo.buttons.save')}</Button>
        </div>
      </div>
      <Snackbar
        open={!!message}
        message={message}
        severity={severity}
        onClose={() => setMessage('')}
      />
    </>
  );
}

export default UserInformation;
