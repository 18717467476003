import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../utils/const/http';

//  Manejo de errores centralizado
const handleApiError = (err) => {
  const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
  const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
  const errorMessage = err.response?.data?.data?.errorMessage || 'Ha ocurrido un error. Intente nuevamente.';

  return {
    success: false,
    status,
    message,
    data: { errorMessage },
  };
};

//  Obtener usuarios y sucursales según el companyId
const getUsersByBranch = async () => {
  try {
    const { data } = await axios.get('/api/user-locations-email');
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return handleApiError(err);
  }
};

export default getUsersByBranch;
