import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    '& .MuiPaper-root': {
      elevation: 3,
    },
  },
  title: {
    fontSize: '19px',
    margin: '10px',
    marginTop: '30px',
    fontWeight: 600,
    color: theme.palette.primary.mainBlue,
  },
  noteContainer: {
    fontSize: '12.5px',
    marginLeft: '10px',
    lineHeight: '150%',
  },
  companySelected: {
    fontSize: '14px',
    margin: '10px 0px 10px 10px',
    color: theme.palette.primary.mainBlue,
  },
  referenceBox: {
    display: 'flex',
    alignItems: 'center',
  },
  referenceNew: {
    borderRadius: '20%',
    marginRight: '5px',
    width: '10px',
    height: '10px',
    backgroundColor: 'green',
  },
  referenceEqual: {
    borderRadius: '20%',
    marginRight: '5px',
    width: '10px',
    height: '10px',
    backgroundColor: theme.palette.primary.mainBlue,
  },
  referenceDelete: {
    borderRadius: '20%',
    marginRight: '5px',
    width: '10px',
    height: '10px',
    backgroundColor: 'red',
  },
}));

export default useStyles;
