import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@material-ui/core';

import { SUPER_ADMIN } from '../../utils/const/user';
import Changes from '../../components/UploadView/Changes';

import { updateUserProjectsChanges, uploadUserProjectsFile } from '../../requests/api/user';

import useStyles from './styles';

function UserProjectsImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'project',
      label: t('users.userProjects.project'),
    },
    {
      id: 'user',
      label: t('users.userProjects.user'),
    },
  ];

  const properties = ['Project', 'User'];

  // Instructions data for the template.
  const instructions = [
    ['Instrucciones'],
    ['1. Completa la hoja "Usuarios-Proyectos" con los datos de usuarios y proyectos.'],
    ['2. En la columna "project" agrega el nombre del proyecto y en la columna "email" agrega el email del usuario.'],
    ['En Administración podrás verificar los nombres de los proyectos.'],
    ['3. Guarda el archivo en formato xlsx o csv (con delimitador ",").'],
    ['Importante! Revisa que la compañía seleccionada en la plataforma sea la que está asociada a los proyectos que vas a importar'],
    [],
    // Example data
    ['Ejemplo'],
    ['project', 'email'],
    ['Mistery Shopper', 'usuario@mail.com'],
    ['Ariztia', 'usuario2@mail.com'],
  ];

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('users.userProjects.uploadFile')}
      </Typography>
      <Box className={classes.companySelected}>
        <Typography variant="p">
          {t('users.userProjects.importView.companySelected')}
        </Typography>
      </Box>
      <Box className={classes.noteContainer}>
        <Typography variant="p" color="textPrimary">
          {t('users.userProjects.importView.fileNote')}
          <br />
          {t('users.userProjects.importView.references')}
        </Typography>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceNew} /> {t('users.userProjects.importView.new')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceEqual} /> {t('users.userProjects.importView.equal')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceDelete} /> {t('users.userProjects.importView.delete')}
        </Box>
      </Box>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          templateColumns={['project', 'email']}
          templateName="user-projects"
          templateSheetName="Usuarios-Proyectos"
          instructions={instructions}
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateUserProjectsChanges}
          uploadFunction={uploadUserProjectsFile}
        />
      </Paper>
    </div>
  );
}

export default UserProjectsImportView;
